import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../src/Components/Styles/media.css'
import PersistedRoute from './Components/Pages/PersistedRoute';
import Logout from './Components/Pages/Logout';
import ProtectedRoute from './Components/Security/Security';



const Login = lazy(() => import('./Components/Pages/Login'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Pages/Dashboard'));
const MyProfile = lazy(() => import('./Components/Pages/MyProfile'));
const Userlist = lazy(() => import('./Components/Pages/Userlist'));
const Useradd = lazy(() => import('./Components/Pages/Useradd'));
const Customer = lazy(() => import('./Components/Pages/Customer'));
const Addcustomer = lazy(() => import('./Components/Pages/Addcustomer'));
const Forgotpass = lazy(() => import('./Components/Pages/Forgotpass'));
const Otp = lazy(() => import('./Components/Pages/Otp'))
const Resetpass = lazy(() => import('./Components/Pages/Resetpass'))
const VehicleRegister = lazy(() => import('./Components/Pages/VehicleRegister'))
const VehicleRegisterList = lazy(() => import('./Components/Pages/VehicleRegisterList'))
const VehicleModel = lazy(() => import('./Components/Pages/VehicleModel'))
const VehicleModelList = lazy(() => import('./Components/Pages/VehicleModelList'))
const VehicleColor = lazy(() => import('./Components/Pages/VehicleColor'))
const VehicleColorList = lazy(() => import('./Components/Pages/VehicleColorList'))
const Subscription = lazy(() => import('./Components/Pages/Subscription'));
const Addsubscription = lazy(() => import('./Components/Pages/Addsubscription'));
const Invoice = lazy(() => import('./Components/Pages/Invoice'));
const Maintenancemode = lazy(() => import('./Components/Pages/Maintenancemode'));
const Maintenence = lazy(() => import('./Components/Pages/Maintenence'));
const NotFound = lazy(() => import('./Components/Pages/Not_found'));
const Addmaintenence = lazy(() => import('./Components/Pages/Addmaintenence'));
const Vehicleassign = lazy(() => import('./Components/Pages/Vehicleassign')); 



function AppContent() {
  const location = useLocation();

  const getPageTitle = (path) => {
    if (path.includes('/useredit/')) {
      return 'Dealer User Edit';
    }
    if (path.includes('/vehicleregisteredit/')) {
      return 'Vehicle Register Edit';
    }
    if (path.includes('/batteryedit')) {
      return 'Battery Edit';
    }
    if (path.includes('/dealeredit')) {
      return 'Dealer Edit';
    }
    if (path.includes('/Editmaintenence')) {
      return 'Edit Maintenence';
    }
    if (path.includes('/editcustomer')) {
      return 'Edit Customer';
    }
    if (path.includes('/editsubscription')) {
      return 'Edit Subscription';
    }
    if (path.includes('/vehicleregister')) {
      return 'Vehicle Register';
    }
    if (path.includes('/vehiclemodel')) {
      return 'Vehicle Model';
    }
    if (path.includes('/vehiclecolor')) {
      return 'Vehicle Color';
    }
    switch (path) {
      case '/dashboard':
        return 'Dashboard';
      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';

      case '/userlist':
        return 'Dealer User List';

      case '/useradd':
        return 'Dealer User Add';  // vehicle register
      case '/vehicleregister':
        return 'Vehicle Register';
      case '/vehicleregisterlist':
        return 'Vehicle Register list'
      case '/vehicleregister/:id':
        return 'Edit Vehicle'


      // vehicle model
      case '/vehiclemodel':
        return 'Vehicle Model';
      case '/vehiclemodellist':
        return 'Vehicle Model List'

      // vehicle color
      case '/vehiclecolor':
        return 'Vehicle Color'
      case '/vehiclecolorlist':
        return 'Vehicle Color List'

      //subscription
      case '/subscription':
        return 'subscription list';
      case '/addsubscription':
        return 'subscription Add';
      case '/editsubscription/:id':

      //customer
      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';
      case '/station':

      case '/maintenence':
        return 'Maintenence List'
      case '/Addmaintenence':
        return ' Add Maintenence '
      case '/Editmaintenence':
        return ' Edit Maintenence '

      default:
        return 'Users';
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/*forgatepass*/}
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetpass" element={<Resetpass />} />


        <Route element={<ProtectedRoute />}>
          <Route element={<Layout pageTitle={getPageTitle(location.pathname)} />}>
            <Route path="/dashboard" element={<PersistedRoute element={<Dashboard />} />} />

            <Route path="/userlist" element={<PersistedRoute element={<Userlist />} />} />
            <Route path="/myprofile" element={<PersistedRoute element={<MyProfile />} />} />
            <Route path="/useradd" element={<PersistedRoute element={<Useradd />} />} />
            <Route path="/useredit/:id" element={<PersistedRoute element={<Useradd />} />} />
            {/* customer */}

            <Route path='/customermanagement' element={<PersistedRoute element={<Customer />} />} />
            <Route path="/addcustomer" element={<PersistedRoute element={<Addcustomer />} />} />
            <Route path="/editcustomer/:id" element={<PersistedRoute element={<Addcustomer />} />} />
            {/* vehicle Register */}
            <Route path='/vehicleregister' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregister/:id' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisterlist' element={<PersistedRoute element={<VehicleRegisterList />} />} />

            {/* Vehicle Model */}
            <Route path='/vehiclemodel' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodel/:id' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodellist' element={<PersistedRoute element={<VehicleModelList />} />} />

            {/* Vehicle Color */}
            <Route path='/vehiclecolor/:id' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolor' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolorlist' element={<PersistedRoute element={<VehicleColorList />} />} />

            {/* subscription */}
            <Route path='/subscription' element={<PersistedRoute element={<Subscription />} />} />
            <Route path='/addsubscription' element={<PersistedRoute element={<Addsubscription />} />} />
            <Route path='/editsubscription/:id' element={<PersistedRoute element={<Addsubscription />} />} />
            <Route path='/invoice' element={<PersistedRoute element={<Invoice />} />} />
            {/* maintenence */}
            <Route path='/maintenence' element={<PersistedRoute element={<Maintenence />} />} />
            <Route path='/Addmaintenence' element={<PersistedRoute element={<Addmaintenence />} />} />
            <Route path='/Editmaintenence/:id' element={<PersistedRoute element={<Addmaintenence />} />} />
            <Route path='/vehicleassign/' element={<PersistedRoute element={<Vehicleassign />} />} />

            {/* Logout */}Invoice
            <Route path='/logout' element={<PersistedRoute element={<Logout />} />} />
          </Route>

        </Route>
      </Routes>
    </Suspense >
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;
