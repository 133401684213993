import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear cookies and local storage
        const currentRoute = window.location.pathname;
        Cookies.set('previousRoute', currentRoute);

        Cookies.remove('Username');
        Cookies.remove('Password');
        Cookies.remove('token'); // Remove the auth token from cookies
        localStorage.clear();

        // Redirect to the login page
        navigate('/'); // Redirect to login or a desired page
    }, [navigate]);

    return null;
};

export default Logout;
